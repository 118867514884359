<template>
    <div class="jiaoyueniubi">
        <!-- <div class="mm" :class="{'active': bizType === 1}" @click="cg(1)">猫享自营</div> -->
        <div class="mm" :class="{'active': bizType === 2}" @click="cg(2)">天猫商家</div>
        <div class="mm" :class="{'active': bizType === 0}" @click="cg(0)">考拉海购</div>
    </div>
</template>


<script>
export default {
    props: ['bizType'],
    methods: {
        cg(index) {
            this.$emit('bizChange', index === this.bizType ? -1 : index);
        }
    }
};
</script>

<style lang='scss' scoped>
    .jiaoyueniubi {
        display: flex;
        align-items: center;
        .mm {
            margin-top: 20px;
            height: 18px;
            width: 60px;
            border: 1px solid #e3e3e3;
            border-radius: 9px;
            background-color: #FFF8F8;
            line-height: 18px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
            &.active {
                color: #f00;
                border-color: #f00;
            }
        }
    }
</style>
