<template>
    <div class="wrap" ref="wrap">
        <div class="top-wrap">
            <div class="top">
                <div class="image-wrap">
                    <img src="https://kaola-haitao.oss.kaolacdn.com/42607e8c-4ec1-43a5-9d76-b733ec5ae93a_46_46.png" alt="">
                </div>
                <div class="title-wrap">
                    <div class="title">
                        <div class="main-title">奖励活动</div>
                        <div class="sub-title">参与奖励活动推广，获取除商品佣金外的额外奖励</div>
                    </div>
                </div>
            </div>
        </div>
        <channel @bizChange="bizChange($event)" :bizType="bizType" />
        <div class="list-wrap" v-if="frontDetailList && frontDetailList.length>0">
            <rewardModel
                v-for="(item,index) in frontDetailList"
                :key="index"
                :item="item"
            />
        </div>
        <div class="list-wrap loading"
             v-else-if="(!frontDetailList || frontDetailList.length === 0) && loading"
             element-loading-text="拼命加载中"
             v-loading="loading"
        />
        <div class="list-wrap nodata"
             v-else-if="(!frontDetailList || frontDetailList.length === 0) && !loading"
        >
            <noData><p>暂无活动</p></noData>
        </div>
        <div class="list-wrap" v-else>
            <errorData>
                <div>
                    <p>数据加载失败</p>
                    <p @click="reload" class="reload">重新加载</p>
                </div>
            </errorData>
        </div>
        <div class="pagination-wrap" v-if="frontDetailList && frontDetailList.length>0">
            <pagination :total="totalAmount"
                        :page-size="pageSize"
                        :current-page="pageIndex"
                        @size-change="sizeChange"
                        @current-change="currentChange"
            />
        </div>
        <rewardDialog />
    </div>
</template>

<script>
import { Message } from 'element-ui';
import pagination from '../../components/common/pagination.vue';
import rewardModel from '../../components/rewardPromotion/rewardModel.vue';
import rewardDialog from '../../components/rewardPromotion/rewardDialog.vue';
import noData from '../../components/common/noData.vue';
import errorData from '../../components/common/errorData.vue';
import channel from '../../components/common/channel.vue';


export default {
    components: {
        pagination,
        rewardModel,
        rewardDialog,
        noData,
        errorData,
        channel
    },
    data() {
        return {
            totalAmount: 0,
            frontDetailList: [],
            pageIndex: 1,
            pageSize: 40,
            loading: true,
            bizType: -1
        };
    },
    created() {
        this.getRewardActivityList();
    },

    methods: {
        bizChange(bizType) {
            if(this.loading) {
                Message.error('数据加载中，请稍后');
                return;
            }

            this.loading = true;
            this.frontDetailList = [];
            this.bizType = bizType;
            this.pageIndex = 1;
            this.getRewardActivityList();
        },
        sizeChange(e) {
            this.pageSize = e;
            this.pageIndex = 1;
            this.getRewardActivityList();
        },
        currentChange(e) {
            this.pageIndex = e;
            this.getRewardActivityList();
        },
        async getRewardActivityList() {
            const params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                type: 1
            };
            params.bizType = this.bizType;

            const data =await this.$request({
                url: '/api/rewardPromotion/getRewardActivityList',
                params
            });

            this.$nextTick(() => {
                this.loading = false;
            });
            this.totalAmount = data.totalAmount;
            this.frontDetailList = data.frontDetailList;
        },
        reload() {
            this.getRewardActivityList();
        }
    }
};
</script>

<style lang="scss" scoped>
    .wrap {
        position: relative;
        .reload {
            cursor: pointer;
            color: #ff1e32;
            text-decoration: underline;
        }
        .top-wrap {
            padding-left: 13px;
            padding-top: 13px;
            height: 80px;
            background: #FFFFFF;
            border: 1px solid #E3E3E3;
            border-radius: 4px;
            display: flex;
            flex-direction:column;
            justify-content: space-between;
            .top {
                display: flex;
                height: 60px;
                align-items: center;
                margin-right: 10px;
                .image-wrap {
                    width: 46px;
                    height: 46px;
                    img {
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                    }
                }
                .title-wrap {
                    height: 46px;
                    margin-left: 10px;
                    padding-top: 2px;
                    .main-title{
                        font-size: 16px;
                        font-weight: 700;
                        color: #333333;
                    }
                    .sub-title {
                        margin-top: 4px;
                        color: #333333;
                        font-size: 12px;
                    }
                }
            }
            .tab-wrap {
                //width: 275px;
                margin: 0 auto;
                // position: relative;
                // top: 14px;
            }
            .fix{
                //width: 100%;
                position: fixed;
                left: 200px;
                right: 0px;
                top: 70px;
                background: #fff;
                z-index: 1003;
                .inner-wrap {
                   width: 325px;
                   margin: 0 auto;
                }
            }
        }
        .pagination-wrap {
            margin-top: 20px;
            text-align: center;
            margin-bottom: 30px;
        }
        .loading {
            margin-top: 200px;
            //background: #f5f5f5;
        }
        .nodata {
            position: relative;
            top:120px;
        }
    }
</style>

<style lang='scss'>
.status-tab{
    .el-tabs__nav-wrap:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0px;
        background-color: #FFF;
        z-index: 1;
    }
    .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 15px 5px 0 5px;
    }
}
</style>
