<template>
    <div class="inner-wrap">
        <div class="edit-wrap" v-if="editFlag">
            <div class="top-wrap">
                <div class="left">
                    选择推广位
                    <tooltip>此处只能选择推广类型为「一般推广」的推广位</tooltip>
                </div>
                <div class="right" @click="newPosition">+新建</div>
            </div>
            <div class="middle-wrap">
                <el-select v-model="value" filterable placeholder="请选择"
                           style="width: 100%;"
                >
                    <el-option
                        v-for="item in options"
                        :key="item.positionId"
                        :label="item.name"
                        :value="item.positionId"
                    />
                </el-select>
            </div>
            <div class="bottom-wrap">
                <el-button size="small" @click="deletePosition" class="deleteBu">
                    取消
                </el-button>
                <el-button type="primary" size="small" @click="confirmPosition" class="createBu">
                    确定
                </el-button>
            </div>
        </div>
        <div class="new-wrap" v-if="!editFlag">
            <div class="top-wrap">
                <div class="left">新建推广位</div>
            </div>
            <div class="middle-wrap">
                <el-form ref="form" label-width="100px">
                    <el-form-item label="推广类型">
                        <el-radio-group v-model="type">
                            <el-radio :label="1">一般推广</el-radio>
                            <el-radio :label="2">内容媒体推广</el-radio>
                        </el-radio-group>
                        <el-select placeholder="请选择"
                                   v-if="type === 2" :value="2"
                        >
                            <el-option
                                label="抖音"
                                :value="2"
                            >
                                抖音
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推广位名称">
                        <el-input v-model="input" placeholder="推广位名称" />
                    </el-form-item>
                </el-form>
            </div>
            <div class="bottom-wrap">
                <el-button size="small" @click="backToEdit" class="deleteBu">
                    取消
                </el-button>
                <el-button type="primary" size="small" @click="createPosition" class="createBu">
                    创建
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import storage from '../../utils/storage.js';
import tooltip from '../../components/settle/tooltip';

export default {
    name: 'selectPrommotion',
    data() {
        return {
            options: [],
            value: '',
            input: '',
            type: 1,
            editFlag: true,
            positionId: '',
            firstEnter: true,
            valueName: '',
            posiName: ''
        };
    },
    props: {
        export: {
            default: false
        }
    },
    components: {
        tooltip
    },
    async created() {
        this.value = storage.get('position')?storage.get('position'):'';
        await this.getCorpZhuankePromotionPosition();
    },
    methods: {
        /*eslint-disable*/
        async getCorpZhuankePromotionPosition() {
            const data = await this.$request({
                url: '/api/promotion/getCorpZhuankePromotionPosition'
            });
            if(data && data.length!==0) {
                this.options = data;
                this.editFlag = true;
            }
            if(!data || data.length ===0) {
                this.editFlag = false;
            }
            if(this.value) {
                if(this.options && this.options.length > 0) {
                    const result = this.options.some(value => value.positionId === this.value);
                    if(!result) {
                        this.value = '';
                    }
                }
                if(!this.options || this.options.length === 0){
                    this.value ='';
                }
            }
        },
        /*eslint-disable*/
        deletePosition() {
            this.$emit('hidePosition');
        },
        newPosition() {
            this.editFlag = false;
        },
        backToEdit() {
            this.editFlag = true;
        },
        /* eslint-disable */
        async createPosition() {
            if(!this.input) {
                this.$message.error('请填写推广位名称');
                return;
            }

            const data = await this.$request({
                method: 'post',
                url: '/api/promotion/createZhuankePromotionPosition',
                params: {
                    positionName: this.input,
                    type: this.type
                }
            });

            if(data) {
                this.value = data;
                this.getCorpZhuankePromotionPosition();
                this.editFlag = true;
                this.input = '';
                this.positionId = data;
            }

        },
        /* eslint-disable */
        confirmPosition() {
            if(!this.value) {
                this.$message.error('请选择推广位！');
                return;
            }
            this.options.forEach((item) => {
                if(item.positionId === this.value) {
                    this.positionId = item.positionId;
                    this.posiName = item.name;
                }
            });
            if(this.export) {
                 this.$emit('confirmPosition',  {
                    posiName:this.posiName,
                    positionId :this.positionId
                })
            }else {
                this.$emit('confirmPosition', this.positionId);
            }
            storage.set('position', this.positionId);
        }
    }
};
</script>

<style lang='scss' scoped>
.inner-wrap {
    margin: 10px 15px;
    .edit-wrap {
        .middle-wrap {
            margin-top: 70px;
        }
    }
    .edit-wrap, .new-wrap {
        .top-wrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            .left {
                font-weight: 700;
                color: #333333;
            }
            .right {
                cursor: pointer;
                color: #666;
            }
        }
        .middle-wrap {
            margin-bottom: 15px;
            .isExit {
                margin-top: 5px;
                color: red;
            }
        }
        .bottom-wrap {
            display: flex;
            justify-content: center;
            .deleteBu {
                margin-right: 5px;
                border-radius: 100px;
                width: 65px;

            }
            .createBu {
                border-radius: 100px;
                background: #ff1e32;
                width: 70px;


            }
        }
    }
}
</style>
