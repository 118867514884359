<template>
    <div class="common-wrap" :style="`margin-top: ${marginTop || 0}px`">
        <div class="remark-wrap">
            <div class="remark-img">
                <img
                    src="https://kaola-haitao.oss.kaolacdn.com/b3e76679-fed7-4533-a501-38925b296ed8_220_180.png"
                    alt=""
                />
            </div>
            <div class="remark">
                <slot />
            </div>
            <div>
                <el-button type="primary" @click="refresh" class="refresh"
                    >刷新</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "noData",
    props: {
        marginTop: 0
    },
    methods: {
        refresh() {
            window.location.reload();
        }
    }
};
</script>

<style lang="scss" scoped>
.common-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .remark-wrap {
        .refresh {
            margin: 15px 0;
        }
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .remark-img {
            width: 165px;
            height: 135px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .remark {
            text-align: center;
        }
    }
}
</style>
