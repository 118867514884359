<template>
    <div class="common-wrap">
        <div class="remark-wrap">
            <div class="remark-img">
                <img src="https://kaola-haitao.oss.kaolacdn.com/b3e76679-fed7-4533-a501-38925b296ed8_220_180.png" alt="">
            </div>
            <div class="remark">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'noData',
    methods: {
        reload() {
            this.$emit('reload');
        }
    }
};
</script>

<style lang='scss' scoped>
.common-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 60px;
    .remark-wrap {
        padding-left: 200px;
        .remark-img {
            width: 165px;
            height: 135px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .remark {
            text-align: center;
        }
    }
}

</style>