import { render, staticRenderFns } from "./tooltip.vue?vue&type=template&id=794e5ad5&scoped=true&"
var script = {}
import style0 from "./tooltip.vue?vue&type=style&index=0&id=794e5ad5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "794e5ad5",
  null
  
)

export default component.exports