<template>
    <div class="zk-pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 40
        },
        total: {
            type: Number,
            default: 0
        },
        pageSizes: {
            type: Array,
            default: () => [20, 40, 60, 80]
        }
    },

    methods: {
        handleSizeChange(size) {
            this.$emit('size-change', size);
        },

        handleCurrentChange(page) {
            this.$emit('current-change', page);
        }
    }
};
</script>
